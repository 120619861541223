import { backendHost } from '../../../common/configuration/ConfigProvider';
import useUserAuth from '../../../common/hooks/useUserAuth';
import { HttpClient, HttpMethod } from '../../../common/api/HttpClient';

export type Props = {
    userId: string;
};

const useUsernameCellController = ({ userId }: Props) => {
    const { userAuth } = useUserAuth();
    const { fetch } = HttpClient();

    const onClick = async () => {
        const salt = await getLoginAsSalt();
        redirectToBadger(salt);
    };

    const getLoginAsSalt = async () => {
        const response = await fetch(HttpMethod.POST, '/api/login-as-user', userAuth.token, { user_id: userId });
        return response.body.salt;
    };

    const redirectToBadger = (salt: string) => {
        const url = `${backendHost}?salt=${salt}`;
        window.open(url, '_blank');
    };

    return { onClick };
};

export { useUsernameCellController };
