import { skipToken } from '@reduxjs/toolkit/query/react';
import { useGetIntegrationConfigurationTemplateQuery } from '../../../../store/api/integrationConfigurationTemplate/integrationConfigurationTemplateAPI';
import { useSelectCompany } from '../../../../common/hooks/useSelectCompany';

const useIntegrationConfigurationTemplatesRepository = () => {
    const { selectedCompanyId } = useSelectCompany();
    const {
        data: integrationConfigurationTemplates,
        isLoading,
        isError,
    } = useGetIntegrationConfigurationTemplateQuery(selectedCompanyId ?? skipToken);

    return {
        integrationConfigurationTemplates,
        isLoading,
        isLoadError: isError,
    };
};

export { useIntegrationConfigurationTemplatesRepository };
