import { Outlet } from 'react-router-dom';
import TabsPanel from './TabsPanel/TabsPanel';
import { styled } from 'styled-components';
import { NoObjectDefinitionsContent } from './NoObjectDefinitionsContent/NoObjectDefinitionsContent';
import { ObjectDefinitionsSelectorModal } from './ObjectDefinitionsSelectorModal/ObjectDefinitionsSelectorModal';
import { useCompanyDefinitionContentController } from './companyDefinitionContentController';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';
import { SpecifyLabelsModal } from './ObjectDefinitionsSelectorModal/SpecifyLabelsModal';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const StyledBackdrop = styled(Backdrop)`
    z-index: ${({ theme }) => theme.zIndex.drawer + 1} !important;
    // TODO: change to theme color, waiting for #BMG-590
    background-color: #ffffff66 !important;
    pointer-events: auto !important;
`;

const CompanyDefinitionContent = () => {
    const {
        tabs,
        isLoading,
        newObjectDefinitions,
        handleNextButton,
        handleBackButton,
        modalObjectDefinitionsSelectorControl,
        modalSpecifyLabelsControl,
    } = useCompanyDefinitionContentController();

    const renderLoading = () => (
        <StyledBackdrop open={isLoading} aria-label="loading-overlay">
            <CircularProgress />
        </StyledBackdrop>
    );

    const renderNoObjectDefinitions = () => (
        <NoObjectDefinitionsContent openObjectTypeSelectionModal={modalObjectDefinitionsSelectorControl.setOn} />
    );

    const renderObjectDefinitionSelectorModal = () => (
        <ObjectDefinitionsSelectorModal
            isOpen={modalObjectDefinitionsSelectorControl.isOn}
            close={modalObjectDefinitionsSelectorControl.setOff}
            nextButton={handleNextButton}
        />
    );

    const renderObjectLabelingModal = () => (
        <SpecifyLabelsModal
            isOpen={modalSpecifyLabelsControl.isOn}
            close={modalSpecifyLabelsControl.setOff}
            backButton={handleBackButton}
            newObjectDefinitions={newObjectDefinitions}
        ></SpecifyLabelsModal>
    );

    return (
        <>
            {isLoading && renderLoading()}
            {!isLoading && tabs.length <= 0 && renderNoObjectDefinitions()}
            {!isLoading && tabs.length > 0 && (
                <Container>
                    <TabsPanel tabs={tabs} handleManageObjectsClick={modalObjectDefinitionsSelectorControl.setOn} />
                    <Outlet />
                </Container>
            )}
            {renderObjectDefinitionSelectorModal()}
            {modalSpecifyLabelsControl.isOn && renderObjectLabelingModal()}
        </>
    );
};

export default CompanyDefinitionContent;
