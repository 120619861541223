import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../commonAPIConfig';
import { PartialUpdateUserAPISchema, UserAPISchema } from './UsersAPISchema';
import { User } from '../../../domain/Users/User';
import { mapUserFromAPI } from './UsersAPIMappers';

export const UsersAPI = createApi({
    reducerPath: 'UsersAPI',
    baseQuery,
    tagTypes: ['UsersAPI'],
    endpoints: (builder) => ({
        patchUser: builder.mutation<User, { userId: string; userData: PartialUpdateUserAPISchema }>({
            query: ({ userId, userData }) => ({
                url: `/api/3/users/${userId}`,
                method: 'PATCH',
                body: userData,
            }),
            transformResponse: (response) => mapUserFromAPI(response as UserAPISchema),
        }),
    }),
});

export const { usePatchUserMutation } = UsersAPI;
