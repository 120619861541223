export const engagementsHS = [
    {
        name: 'hs_call_disposition',
        label: 'Call - Outcome',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
            {
                label: 'Wrong number',
                value: '17b47fee-58de-441e-a44c-c6300d46f273',
                order: 0,
            },
            {
                label: 'No answer',
                value: '73a0d17f-1163-4015-bdd5-ec830791da20',
                order: 1,
            },
            {
                label: 'Busy',
                value: '9d9162e7-6cf3-4944-bf63-4dff82258764',
                order: 2,
            },
            {
                label: 'Left live message',
                value: 'a4c4c377-d246-4b32-a13b-75a56a4cd0ff',
                order: 3,
            },
            {
                label: 'Left voicemail',
                value: 'b2cf5968-551e-4856-9783-52b3da59a7d0',
                order: 4,
            },
            {
                label: 'Connected',
                value: 'f240bbac-87c9-4f6e-bf70-924b57d47db7',
                order: 5,
            },
        ],
    },
    {
        name: 'hs_engagement_type',
        label: 'Activity Type',
        isMandatory: true,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_TITLE',
        type: 'picklist',
        options: [
            {
                label: 'Call',
                value: 'CALL',
                order: 0,
            },
            {
                label: 'Meeting',
                value: 'MEETING',
                order: 1,
            },
            {
                label: 'Note',
                value: 'NOTE',
                order: 2,
            },
            {
                label: 'Task',
                value: 'TASK',
                order: 3,
            },
        ],
    },
    {
        name: 'hs_call_direction',
        label: 'Call - Direction',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
            {
                label: 'Outbound',
                value: 'OUTBOUND',
                order: 0,
            },
            {
                label: 'Inbound',
                value: 'INBOUND',
                order: 1,
            },
        ],
    },
    {
        name: 'hs_activity_type',
        label: 'Call / Meeting Type',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
            {
                label: 'Cold Call (Phone)',
                value: 'Cold Call (Phone)',
                order: 0,
            },
            {
                label: 'Cold Call (In Person - Met DM)',
                value: 'Cold Call (In Person - Met DM)',
                order: 1,
            },
            {
                label: 'Cold Call (In Person - No DM Met)',
                value: 'Cold Call (In Person - No DM Met)',
                order: 2,
            },
            {
                label: 'Event Contact (ex. Tradeshow)',
                value: 'Event Contact (ex. Tradeshow)',
                order: 3,
            },
            {
                label: 'Sales Opportunity Meeting',
                value: 'Sales Opportunity Meeting',
                order: 4,
            },
            {
                label: 'Virtual Meeting',
                value: 'Virtual Meeting',
                order: 5,
            },
            {
                label: 'Set AE Appointment',
                value: 'Set AE Appointment',
                order: 6,
            },
            {
                label: 'Webinar Follow-Up',
                value: 'Webinar Follow-Up',
                order: 7,
            },
            {
                label: 'Follow-up Phone Call',
                value: 'Follow-up Phone Call',
                order: 8,
            },
            {
                label: 'Networking Activity',
                value: 'Networking Activity',
                order: 9,
            },
            {
                label: 'Implementation',
                value: 'Implementation',
                order: 10,
            },
            {
                label: 'QBR/Renewal',
                value: 'QBR/Renewal',
                order: 11,
            },
            {
                label: 'Problem Resolution',
                value: 'Problem Resolution',
                order: 12,
            },
            {
                label: 'Local Engagement Tool Kit',
                value: 'Local Engagement Tool Kit',
                order: 13,
            },
        ],
    },
    {
        name: 'hs_task_body',
        label: 'Task - Notes',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: true,
    },
    {
        name: 'hs_note_body',
        label: 'Note - Notes',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: true,
    },
    {
        name: 'hs_task_type',
        label: 'Task - Type',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
            {
                label: 'Call',
                value: 'CALL',
                order: 0,
            },
            {
                label: 'Email',
                value: 'EMAIL',
                order: 1,
            },
            {
                label: 'To Do',
                value: 'TODO',
                order: 2,
            },
        ],
    },
    {
        name: 'hs_meeting_body',
        label: 'Meeting - Notes',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: true,
    },
    {
        name: 'hs_task_subject',
        label: 'Task - Subject',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'hs_task_priority',
        label: 'Task - Priority',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
            {
                label: 'None',
                value: 'NONE',
                order: 0,
            },
            {
                label: 'Low',
                value: 'LOW',
                order: 1,
            },
            {
                label: 'Medium',
                value: 'MEDIUM',
                order: 2,
            },
            {
                label: 'High',
                value: 'HIGH',
                order: 3,
            },
        ],
    },
    {
        name: 'hs_call_body',
        label: 'Call - Notes',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'text',
        isTextArea: true,
    },
    {
        name: 'hs_meeting_outcome',
        label: 'Meeting - Outcome',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
            {
                label: 'Scheduled',
                value: 'SCHEDULED',
                order: 0,
            },
            {
                label: 'Completed',
                value: 'COMPLETED',
                order: 1,
            },
            {
                label: 'Rescheduled',
                value: 'RESCHEDULED',
                order: 2,
            },
            {
                label: 'No Show',
                value: 'NO_SHOW',
                order: 3,
            },
            {
                label: 'Canceled',
                value: 'CANCELED',
                order: 4,
            },
        ],
    },
    {
        name: 'hs_createdate',
        label: 'Created Date',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: true,
        displayType: 'AS_SUBTITLE',
        type: 'datetime',
    },
];
