import ModalSelector from '../../../common/components/ModalSelector';
import { useCrmObjectTypes } from './hooks/useCrmObjectTypes';
import { useSelectedObjectTypes } from './hooks/useSelectedObjectTypes';
import { mapObjectTypesToModalSelectorObjects } from './mappers/mapObjectTypesToModalSelectorObjects';
import { useCompanyDefinitionRepository } from '../../../common/hooks/useCompanyDefinitionRepository';
import { ObjectType } from '../../../domain/Crm/ObjectType';
import { NewObjectDefinition } from '../defs';

export const ObjectDefinitionsSelectorModal = ({
    isOpen,
    close,
    nextButton,
}: {
    isOpen: boolean;
    close: () => void;
    nextButton: (newObjectDefinitions: NewObjectDefinition[]) => void;
}) => {
    const { isLoading, objectTypes } = useCrmObjectTypes();
    const { selectedObjectTypeIds, toggleObjectType } = useSelectedObjectTypes();
    const { companyDefinition } = useCompanyDefinitionRepository();

    const modalSelectorObjectTypes = mapObjectTypesToModalSelectorObjects(objectTypes, companyDefinition);

    const getSelectedObjectsByIndexes = (): ObjectType[] => {
        return selectedObjectTypeIds.map((index) => objectTypes[Number(index)]);
    };

    const handleOnConfirm = () => {
        nextButton(getSelectedObjectsByIndexes());
        close();
    };

    return (
        <ModalSelector
            visible={isOpen}
            onConfirm={() => {
                handleOnConfirm();
            }}
            onClose={close}
            objects={modalSelectorObjectTypes}
            selectedObjectIds={selectedObjectTypeIds}
            loading={isLoading}
            handleOnObjectClick={toggleObjectType}
            modalTitle={'Retrieve objects from CRM'}
            modalSubtitle={'Select objects'}
            objectDescription={'CRM API Name / CRM Label'}
            uncheckedLabel={'Select'}
            checkedLabel={'Selected to be retrieved'}
            primaryButtonCaption={'Next'}
            secondaryButtonCaption={'Back'}
            secondaryButtonAction={close}
        />
    );
};
