import Box from '@mui/material/Box';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Dialog, loadFontAttributes } from 'design-system';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { BadgerObjectDefinition } from '../../domain/CompanyDefinition/CompanyDefinition';

const StyledTypography = styled(Typography)`
    padding: 20px;
    ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)};
`;

const StyledFormGroup = styled(FormGroup)`
    justify-content: center;
    align-content: center;
    row-gap: -2px;
    padding: 10px 20px 20px 20px;

    .MuiFormControlLabel-root {
        height: 30px;
    }
`;

const StyledFormControlLabel = styled(FormControlLabel)`
    .MuiTypography-root {
        ${({ theme }) => loadFontAttributes(theme.typography.Medium.regular)} !important;
    }
`;

export interface Props {
    availableBadgerObjectDefinitions: BadgerObjectDefinition[];
    isOpen: boolean;
    onClose: () => void;
    onChoose: () => void;
    chosenBadgerObjectDefinitions: BadgerObjectDefinition[];
    setChosenBadgerObjectDefinitions: (badgerObjectDefinition: BadgerObjectDefinition[]) => void;
}

export const ChooseDataTypeModal = ({
    availableBadgerObjectDefinitions,
    isOpen,
    onClose,
    onChoose,
    chosenBadgerObjectDefinitions,
    setChosenBadgerObjectDefinitions,
}: Props) => {
    const _handleAllCheckboxesChange = () => {
        if (_areAllChosen()) {
            setChosenBadgerObjectDefinitions([]);
        } else {
            setChosenBadgerObjectDefinitions([...availableBadgerObjectDefinitions]);
        }
    };
    const _areAllChosen = () => chosenBadgerObjectDefinitions.length === availableBadgerObjectDefinitions.length;

    const _areNoneChosen = () => chosenBadgerObjectDefinitions.length === 0;

    const _handleCheckboxChange = (recordDefinition: BadgerObjectDefinition) => {
        if (_isChosen(recordDefinition)) {
            _discard(recordDefinition);
        } else {
            _choose(recordDefinition);
        }
    };

    const _isChosen = (badgerObjectDefinition: BadgerObjectDefinition) =>
        chosenBadgerObjectDefinitions.some((r) => r.name === badgerObjectDefinition.name);

    const _choose = (badgerObjectDefinition: BadgerObjectDefinition) => {
        setChosenBadgerObjectDefinitions([...chosenBadgerObjectDefinitions, badgerObjectDefinition]);
    };

    const _discard = (badgerObjectDefinition: BadgerObjectDefinition) => {
        setChosenBadgerObjectDefinitions(
            chosenBadgerObjectDefinitions.filter((r) => r.name !== badgerObjectDefinition.name)
        );
    };

    const _renderContent = () => (
        <Box>
            <StyledTypography align={'center'}>
                Choose the Data types you would like to retrieve from the CRM:
            </StyledTypography>
            {availableBadgerObjectDefinitions.length !== 0 ? (
                <StyledFormGroup>
                    <StyledFormControlLabel
                        control={<Checkbox />}
                        label="Select All Data"
                        onChange={_handleAllCheckboxesChange}
                        checked={_areAllChosen()}
                    />
                    {availableBadgerObjectDefinitions.map((badgerObjectDefinition: BadgerObjectDefinition) => (
                        <StyledFormControlLabel
                            key={badgerObjectDefinition.name}
                            control={
                                <Checkbox
                                    checked={_isChosen(badgerObjectDefinition)}
                                    onChange={() => _handleCheckboxChange(badgerObjectDefinition)}
                                />
                            }
                            label={badgerObjectDefinition.labelPlural}
                        />
                    ))}
                </StyledFormGroup>
            ) : (
                <StyledTypography align={'center'}>No data available for syncing for this company.</StyledTypography>
            )}
        </Box>
    );

    return (
        <Dialog
            title="Choose data type"
            open={isOpen}
            onClose={onClose}
            primaryButtonCaption={'Choose selection'}
            onPrimaryButtonClick={onChoose}
            content={_renderContent()}
            secondaryButtonCaption={'Go back'}
            onSecondaryButtonClick={onClose}
            primaryButtonDisabled={_areNoneChosen()}
        />
    );
};
