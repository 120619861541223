import { useEffect, useState } from 'react';
import { DataSyncService } from '../services/DataSyncService';
import { useSelectCompany } from '../../../common/hooks/useSelectCompany';
import { DataSyncRepository } from '../repositories/DataSyncRepository';
import { DataSync } from '../../../domain/Crm/DataSync';
import { Import } from '../../../domain/Records/Import';
import { ImportRepository } from '../repositories/ImportRepository';
import { ImportErrorsDownloader } from './ImportErrorsDownloader';
import { ImportError } from '../../../domain/Records/ImportError';
import { ImportErrorsRepository } from '../repositories/ImportErrorsRepository';
import { useCompanyDefinitionRepository } from '../../../common/hooks/useCompanyDefinitionRepository';
import { BadgerObjectDefinition } from '../../../domain/CompanyDefinition/CompanyDefinition';
import { RecordDefinition } from '../../../domain/Records/RecordDefinition';

const POLLING_INTERVAL_SECONDS = 30;

const useCompanySyncDataMainContentController = () => {
    const { companyDefinition } = useCompanyDefinitionRepository();
    const { selectedCompanyId } = useSelectCompany();
    const [showSelectObjectsModal, setShowSelectObjectsModal] = useState(false);
    const [chosenBadgerObjectDefinitions, setChosenBadgerObjectDefinitions] = useState<BadgerObjectDefinition[]>(
        companyDefinition?.badgerObjectDefinitions ?? []
    );
    const [availableBadgerObjectDefinitions, setAvailableBadgerObjectDefinitions] = useState<BadgerObjectDefinition[]>(
        []
    );
    const { resetProcess } = DataSyncService();

    // TODO: Refactor endpoint to use company definition and remove this function
    const mapBadgerObjectDefinitionsToRecordDefinition = (
        badgerObjectDefinitions: BadgerObjectDefinition[]
    ): RecordDefinition[] => {
        return badgerObjectDefinitions.map((badgerObjectDefinition: BadgerObjectDefinition) => ({
            object_type: badgerObjectDefinition.name,
            label: badgerObjectDefinition.labelPlural,
        }));
    };
    const dataSyncRepository = DataSyncRepository();
    const [currentDataSync, setCurrentDataSync] = useState<DataSync | null>(null);

    const importRepository = ImportRepository();
    const [currentImport, setCurrentImport] = useState<Import | null>(null);

    const { triggerDataSync } = DataSyncService();
    const { tryAgainDataSync } = DataSyncService();

    const importErrorsRepository = ImportErrorsRepository();
    const { triggerImportErrorsDownloadForUser } = ImportErrorsDownloader();

    const [importErrors, setImportErrors] = useState<ImportError[] | null>(null);

    const triggerSync = async () => {
        if (selectedCompanyId) {
            setShowSelectObjectsModal(false);
            const recordDefinitions = mapBadgerObjectDefinitionsToRecordDefinition(chosenBadgerObjectDefinitions);
            const dataSync = await triggerDataSync(selectedCompanyId, recordDefinitions);
            setCurrentDataSync(dataSync);
        }
    };

    const tryAgainTriggerSync = async () => {
        if (selectedCompanyId) {
            const currentDataSync = await dataSyncRepository.getCurrent(selectedCompanyId);
            if (currentDataSync !== null) {
                const dataSync = await tryAgainDataSync(selectedCompanyId, currentDataSync);
                setCurrentDataSync(dataSync);
            }
        }
    };

    const tryAgainTriggerImport = async () => {
        if (selectedCompanyId && currentImport) {
            const importData = await importRepository.post(selectedCompanyId, currentImport, 'S3');
            setCurrentImport(importData);
        }
    };

    useEffect(() => {
        if (companyDefinition) {
            setAvailableBadgerObjectDefinitions(companyDefinition.badgerObjectDefinitions);
        }
    }, [companyDefinition]);

    const updateCurrentDataSync = async () => {
        if (selectedCompanyId) {
            const dataSync = await dataSyncRepository.getCurrent(selectedCompanyId);
            setCurrentDataSync(dataSync);
            if (dataSync && dataSync.importId) {
                const importData = await importRepository.get(dataSync.importId);
                setCurrentImport(importData);
                if (importData?.status === 'SUCCESS' && importErrors === null) {
                    const errors = await importErrorsRepository.getImportErrors(importData.id);
                    setImportErrors(errors);
                }
            } else {
                setCurrentImport(null);
            }
        }
    };

    useEffect(() => {
        updateCurrentDataSync();
        const interval = setInterval(() => {
            updateCurrentDataSync();
        }, 1000 * POLLING_INTERVAL_SECONDS);
        return () => clearInterval(interval);
    }, [companyDefinition]);

    const clearProcess = async () => {
        if (selectedCompanyId) {
            await resetProcess(selectedCompanyId);
        }
        updateCurrentDataSync();
    };

    const downloadImportErrors = () => {
        if (currentImport?.id && importErrors) {
            triggerImportErrorsDownloadForUser(currentImport.id, importErrors);
        }
    };

    return {
        showSelectObjectsModal,
        setShowSelectObjectsModal,
        chosenBadgerObjectDefinitions,
        setChosenBadgerObjectDefinitions,
        availableBadgerObjectDefinitions,
        triggerSync,
        currentDataSync,
        currentImport,
        clearProcess,
        downloadImportErrors,
        importErrors,
        tryAgainTriggerSync,
        tryAgainTriggerImport,
    };
};

export { useCompanySyncDataMainContentController };

export type useCompanySyncDataMainContentControllerType = typeof useCompanySyncDataMainContentController;
