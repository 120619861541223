import { ObjectTypeFields } from '../../../domain/Crm/ObjectTypeFields';
import { ObjectTypeFieldsSchema } from './ObjectTypeFieldsSchema';

export const mapFromAPI = (response: ObjectTypeFieldsSchema): ObjectTypeFields =>
    response.results.map((field) => ({
        name: field.name,
        apiName: field.api_name,
        type: field.type,
        isRequired: field.is_required,
        isReadOnly: field.is_read_only,
        options: field.options.map((option) => ({
            value: option.value,
            label: option.label,
        })),
    }));
