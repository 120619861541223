import { CrmUser, CrmUserId } from '../../domain/Users/CrmUser';

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { createCompanyCrmUsers, fetchCompanyCrmUsers } from '../middleware/companyCrmUsers/companyCrmUsersThunks';
import { UserCreationResult } from '../../domain/Users/UserCreationResult';
import { DraftUser } from '../../domain/Users/DraftUser';

interface CompanyCrmUsersState {
    users: CrmUser[];
    selectedCrmUserIds: CrmUserId[];
    isLoading: boolean;
    draftUsers: DraftUser[];
    creationResults: UserCreationResult[];
}

const initialState: CompanyCrmUsersState = {
    users: [],
    selectedCrmUserIds: [],
    isLoading: false,
    draftUsers: [],
    creationResults: [],
};

const companyCrmUsersSlice = createSlice({
    name: 'crmUsers',
    initialState,
    reducers: {
        updateUsers: (state, action: PayloadAction<CrmUser[]>) => {
            state.users = action.payload;
        },
        updateSelectedCrmUserIds: (state, action: PayloadAction<CrmUserId[]>) => {
            state.selectedCrmUserIds = action.payload;
        },
        updateUserCreationResult: (state, action: PayloadAction<UserCreationResult[]>) => {
            state.creationResults = action.payload;
        },
        updateDraftUsers: (state, action: PayloadAction<DraftUser[]>) => {
            state.draftUsers = action.payload;
        },
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCompanyCrmUsers.fulfilled, (state, action) => {
            state.users = action.payload;
            state.isLoading = false;
        });
        builder.addCase(fetchCompanyCrmUsers.rejected, (state) => {
            state.users = [];
            state.selectedCrmUserIds = [];
            state.draftUsers = [];
            state.isLoading = false;
        });
        builder.addCase(fetchCompanyCrmUsers.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(createCompanyCrmUsers.fulfilled, (state, action) => {
            state.creationResults = action.payload;
            state.isLoading = false;
        });
        builder.addCase(createCompanyCrmUsers.rejected, (state) => {
            state.creationResults = [];
            state.isLoading = false;
        });
        builder.addCase(createCompanyCrmUsers.pending, (state) => {
            state.isLoading = true;
        });
    },
});

export const { updateUsers, updateSelectedCrmUserIds, updateUserCreationResult, updateDraftUsers, resetState } =
    companyCrmUsersSlice.actions;

export const selectCompanyCrmUsers = (state: RootState) => {
    return state.companyCrmUsers.users;
};

export const selectSelectedCompanyCrmUsers = (state: RootState) => {
    return state.companyCrmUsers.selectedCrmUserIds;
};

export const selectIsLoading = (state: RootState) => {
    return state.companyCrmUsers.isLoading;
};

export const selectUserCreationResults = (state: RootState) => {
    return state.companyCrmUsers.creationResults;
};

export const selectDraftUsers = (state: RootState) => {
    return state.companyCrmUsers.draftUsers;
};

export default companyCrmUsersSlice.reducer;
