import {
    GenericIntegrationConfigurationTemplate,
    IntegrationConfigurationTemplate,
    IntegrationConfigurationTemplates,
} from '../../../domain/IntegrationConfigurationTemplate/IntegrationConfigurationTemplate';
import {
    mapMetadataFields,
    mapObjectIntegrationConfiguration,
} from '../integrationConfiguration/IntegrationConfigurationMapper';
import {
    GenericIntegrationConfigurationTemplateSchema,
    IntegrationConfigurationTemplateSchema,
    IntegrationConfigurationTemplatesSchema,
} from './IntegrationConfigurationTemplateSchema';

const mapIntegrationConfigurationTemplates = (
    rawIntegrationConfigurationTemplates: IntegrationConfigurationTemplateSchema[]
): IntegrationConfigurationTemplate[] => {
    return rawIntegrationConfigurationTemplates.map(
        (rawIntegrationConfigurationTemplate: IntegrationConfigurationTemplateSchema) => {
            return {
                name: rawIntegrationConfigurationTemplate.name,
                objectIntegrationConfiguration: mapObjectIntegrationConfiguration(
                    rawIntegrationConfigurationTemplate.object_integration_configuration
                ),
            };
        }
    );
};

const mapGenericIntegrationConfigurationTemplates = (
    rawGenericIntegrationConfigurationTemplate: GenericIntegrationConfigurationTemplateSchema
): GenericIntegrationConfigurationTemplate => {
    return {
        customJs: rawGenericIntegrationConfigurationTemplate.custom_js,
        metadataFields: mapMetadataFields(rawGenericIntegrationConfigurationTemplate.metadata_fields),
    };
};

const mapFromAPI = (
    rawIntegrationConfigurationTemplate: IntegrationConfigurationTemplatesSchema
): IntegrationConfigurationTemplates => {
    return {
        templates: mapIntegrationConfigurationTemplates(rawIntegrationConfigurationTemplate.templates),
        genericTemplates: mapGenericIntegrationConfigurationTemplates(
            rawIntegrationConfigurationTemplate.generic_template
        ),
    };
};

export { mapFromAPI };
