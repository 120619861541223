import { Dialog, loadFontAttributes } from 'design-system';
import styled from 'styled-components';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import { ROW_COLUMN_SEPARATOR, NewObjectDefinition } from '../defs';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TextTypeField from '../ObjectDefinitionsTable/TextTypeField/TextTypeField';
import { Column, ObjectLabelRow } from './defs';
import PicklistTypeField from '../ObjectDefinitionsTable/PicklistTypeField/PicklistTypeField';
import { useSpecifyLabelsModalController } from './SpecifyLabelsModalController';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';

const StyledTableCell = styled(TableCell)<{ $width: number; $align: string; $hidden?: boolean }>`
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    width: ${({ $width }) => $width}% !important;
    min-width: ${({ $width }) => $width}px !important;
    text-align: ${({ $align }) => $align} !important;

    &.MuiTableCell-root {
        ${({ $hidden }) => $hidden && 'display: none !important;'};
    }
`;

const HeaderTableCell = styled(StyledTableCell)`
    ${({ theme }) => loadFontAttributes(theme.typography.Large.bold)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    height: 42px;
`;

const StyledTableHead = styled(TableHead)`
    background-color: ${({ theme }) => theme.palette.Basic.default_10};
    justify-content: space-between;
    width: 100%;
`;

const BodyTableCell = styled(StyledTableCell)<{ $width: number; $align?: string; $hidden?: boolean }>`
    background-color: ${({ theme }) => theme.palette.Background.secondary.default};
    ${({ theme }) => loadFontAttributes(theme.typography.Large.regular)};
    position: relative;
    height: 60px;
    width: 100%;
`;

const StyledDisabledTextField = styled(StyledTableCell)<{ $width: number; $align?: string; $hidden?: boolean }>`
    ${({ theme }) => loadFontAttributes(theme.typography.Large.regular)};
    color: ${({ theme }) => theme.palette.Text.onSurface.xHigh};
    border-bottom: none !important;
`;

const StyledBackdrop = styled(Backdrop)`
    z-index: ${({ theme }) => theme.zIndex.drawer + 1} !important;
    // TODO: change to theme color, waiting for #BMG-590
    background-color: #ffffff66 !important;
    pointer-events: auto !important;
`;

const StyledTable = styled(Table)`
    width: 100% !important;
`;

export const SpecifyLabelsModal = ({
    isOpen,
    close,
    backButton,
    newObjectDefinitions,
}: {
    isOpen: boolean;
    close: () => void;
    backButton: () => void;
    newObjectDefinitions: NewObjectDefinition[];
}) => {
    const { rows, isSavingChanges, handleSaveChanges, columns, buildTemplateOptions, control, isDirty, isValid } =
        useSpecifyLabelsModalController(newObjectDefinitions, close);

    const renderColumnHeader = (column: Column) => {
        return (
            <HeaderTableCell $width={column.width} key={`col-${column.name}`} $align="center">
                {column.label}
            </HeaderTableCell>
        );
    };

    const renderHeader = () => {
        return <TableRow key="header-row">{columns.map((column) => renderColumnHeader(column))}</TableRow>;
    };

    const renderTemplatesPicklist = (name: string) => {
        return (
            <PicklistTypeField
                disabled={false}
                name={name}
                rules={{ required: false }}
                control={control}
                options={buildTemplateOptions()}
            />
        );
    };

    const renderItem = (row: ObjectLabelRow, column: Column) => {
        const name = `${row.uuid}${ROW_COLUMN_SEPARATOR}${column.name}`;

        if (column.type === 'pick_list') {
            return row.newObject ? renderTemplatesPicklist(name) : <></>;
        }

        return column.editable ? (
            <TextTypeField name={name} rules={{ required: true }} control={control} />
        ) : (
            <StyledDisabledTextField $align="center" $width={column.width}>
                {row[column.name]}
            </StyledDisabledTextField>
        );
    };

    const renderRows = (rows: ObjectLabelRow[]) => {
        return rows.map((row: ObjectLabelRow, rowIndex: number) => (
            <TableRow key={`row-${row.uuid}`}>
                {columns.map((column, colIndex: number) => (
                    <BodyTableCell $width={column.width} key={`${rowIndex}-${colIndex}`}>
                        {renderItem(row, column)}
                    </BodyTableCell>
                ))}
            </TableRow>
        ));
    };

    const buildContent = () => {
        return (
            <StyledTable>
                <StyledTableHead id="table-header" data-testid="table-header">
                    {renderHeader()}
                </StyledTableHead>
                <TableBody>{renderRows(rows)}</TableBody>
                {isSavingChanges && renderLoading()}
            </StyledTable>
        );
    };
    const renderLoading = () => (
        <StyledBackdrop open={isSavingChanges} aria-label="loading-overlay">
            <CircularProgress />
        </StyledBackdrop>
    );

    return (
        <>
            <Dialog
                open={isOpen}
                onClose={close}
                title="Retrieve objects from CRM"
                subtitle="Name selected objects"
                primaryButtonCaption="Confirm"
                secondaryButtonCaption="Back"
                onPrimaryButtonClick={handleSaveChanges}
                onSecondaryButtonClick={backButton}
                primaryButtonDisabled={!isDirty || !isValid || isSavingChanges}
                content={buildContent()}
                maxWidth="xl"
            />
        </>
    );
};
