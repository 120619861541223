export const companiesHS = [
    {
        name: 'name',
        label: 'Company Name',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'phone',
        label: 'Phone Number',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        sorting_order: 0,
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'address',
        label: 'Address',
        isMandatory: false,
        isSearchable: false,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        display_type: 'AS_DETAIL',
        type: 'address',
        accuracyTypes: ['ROOFTOP', 'RANGE_INTERPOLATED'],
    },
];
