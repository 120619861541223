import {
    BaseField,
    CRMAddressMap,
    CRMFieldMap,
    Field,
    FieldType,
    IntegrationConfiguration,
    MetadataFields,
    ObjectIntegrationConfiguration,
} from '../../../domain/IntegrationConfiguration/IntegrationConfiguration';
import {
    IntegrationConfigurationSchema,
    MainRelatedObjectSchema,
    ObjectIntegrationConfigurationSchema,
    CRMExtraIntegratedFieldSchema,
    BaseFieldSchema,
    FieldSchema,
    CRMFieldMapSchema,
    PickListOptionSchema,
    MetadataFieldsSchema,
} from './IntegrationConfigurationSchema';

const mapObjectIntegrationConfigurationMetadataFields = (
    rawObjectIntegrationConfiguration: ObjectIntegrationConfigurationSchema
): MetadataFields => mapMetadataFields(rawObjectIntegrationConfiguration.metadata_fields);

const mapMetadataFields = (rawMetadataFields: MetadataFieldsSchema): MetadataFields => {
    return {
        externalOwnerId:
            rawMetadataFields.external_owner_id != null
                ? {
                      crmName: rawMetadataFields.external_owner_id.crm_name,
                      type: rawMetadataFields.external_owner_id.type,
                  }
                : null,
        externalRecordId: {
            crmName: rawMetadataFields.external_record_id.crm_name,
            type: rawMetadataFields.external_record_id.type,
        },
        lastModificationDate: {
            crmName: rawMetadataFields.last_modification_date.crm_name,
            type: rawMetadataFields.last_modification_date.type,
        },
        creationDate: {
            crmName: rawMetadataFields.creation_date.crm_name,
            type: rawMetadataFields.creation_date.type,
        },
    };
};

const mapCRMMapping = (rawField: BaseFieldSchema): CRMFieldMap | CRMAddressMap | null => {
    if (rawField.crm_mapping && 'city' in rawField.crm_mapping) {
        return {
            city: mapCRMFieldMap(rawField.crm_mapping.city),
            country: mapCRMFieldMap(rawField.crm_mapping.country),
            zipCode: mapCRMFieldMap(rawField.crm_mapping.zip_code),
            state: mapCRMFieldMap(rawField.crm_mapping.state),
            street: mapCRMFieldMap(rawField.crm_mapping.street),
            latitude: rawField.crm_mapping.latitude ? mapCRMFieldMap(rawField.crm_mapping.latitude) : null,
            longitude: rawField.crm_mapping.longitude ? mapCRMFieldMap(rawField.crm_mapping.longitude) : null,
        };
    }
    return rawField.crm_mapping ? mapCRMFieldMap(rawField.crm_mapping) : null;
};

const mapCRMFieldMap = (rawField: CRMFieldMapSchema): CRMFieldMap => {
    return {
        crmFieldName: rawField.crm_field_name,
        crmFieldType: rawField.crm_field_type,
    };
};

const mapBaseField = (rawField: BaseFieldSchema): BaseField => {
    return {
        type: rawField.type,
        id: rawField.id.toString(),
        name: rawField.name,
        label: rawField.label,
        isMandatory: rawField.is_mandatory,
        isSearchable: rawField.is_searchable,
        isFilterable: rawField.is_filterable,
        isColorizable: rawField.is_colorizable,
        isViewOnly: rawField.is_view_only,
        displayOrder: rawField.display_order,
        sortingOrder: rawField.sorting_order,
        sortingOrderDirection: rawField.sorting_order_direction,
        displayType: rawField.display_type,
        crmMapping: mapCRMMapping(rawField),
    };
};

const mapFields = (rawFields: FieldSchema[]): Field[] => {
    return rawFields.map((rawField: FieldSchema) => {
        switch (rawField.type) {
            case FieldType.TEXT:
                return {
                    ...mapBaseField(rawField),
                    type: FieldType.TEXT,
                    isTextArea: rawField.is_text_area,
                    maxLength: rawField.max_length,
                };
            case FieldType.NUMBER:
                return {
                    ...mapBaseField(rawField),
                    type: FieldType.NUMBER,
                    minValue: rawField.min_value,
                    maxValue: rawField.max_value,
                    decimals: rawField.decimals,
                };
            case FieldType.PICK_LIST:
                return {
                    ...mapBaseField(rawField),
                    type: FieldType.PICK_LIST,
                    options: rawField.options.map((rawOption: PickListOptionSchema) => {
                        return {
                            id: rawOption.id ? rawOption.id.toString() : null,
                            label: rawOption.label,
                            value: rawOption.value,
                            order: rawOption.order,
                        };
                    }),
                };
            case FieldType.ADDRESS:
                return {
                    ...mapBaseField(rawField),
                    type: FieldType.ADDRESS,
                    isMain: rawField.is_main,
                    accuracyTypes: rawField.accuracy_types,
                };
            case FieldType.DATE_TIME:
                return {
                    ...mapBaseField(rawField),
                    type: FieldType.DATE_TIME,
                };
            case FieldType.LOOK_UP:
                return {
                    ...mapBaseField(rawField),
                    type: FieldType.LOOK_UP,
                    relatedObjectTypes: rawField.related_object_types,
                };
            default:
                throw new Error(`Unknown field type: ${rawField}`);
        }
    });
};

const mapExtraIntegratedFields = (rawObjectIntegrationConfiguration: ObjectIntegrationConfigurationSchema) => {
    return rawObjectIntegrationConfiguration.extra_integrated_fields.map(
        (rawExtraIntegratedField: CRMExtraIntegratedFieldSchema) => {
            return {
                fieldName: rawExtraIntegratedField.field_name,
                crmName: rawExtraIntegratedField.crm_name,
                type: rawExtraIntegratedField.type,
            };
        }
    );
};

const mapMainRelatedObject = (rawMainRelatedObject: MainRelatedObjectSchema) => {
    return {
        relatedObjectType: rawMainRelatedObject.badger_object_definition,
        lookUpField: rawMainRelatedObject.lookup_field,
    };
};

const mapObjectIntegrationConfigurations = (
    rawObjectIntegrationConfigurations: ObjectIntegrationConfigurationSchema[]
): ObjectIntegrationConfiguration[] => {
    return rawObjectIntegrationConfigurations.map(
        (rawObjectIntegrationConfiguration: ObjectIntegrationConfigurationSchema) =>
            mapObjectIntegrationConfiguration(rawObjectIntegrationConfiguration)
    );
};

const mapObjectIntegrationConfiguration = (rawObjectIntegrationConfiguration: ObjectIntegrationConfigurationSchema) => {
    return {
        id: rawObjectIntegrationConfiguration.id ? rawObjectIntegrationConfiguration.id.toString() : null,
        mappingId: rawObjectIntegrationConfiguration.mapping_id,
        objectType: rawObjectIntegrationConfiguration.object_type,
        crmObjectType: rawObjectIntegrationConfiguration.crm_object_type,
        label: rawObjectIntegrationConfiguration.label,
        labelPlural: rawObjectIntegrationConfiguration.label_plural,
        isRoot: rawObjectIntegrationConfiguration.is_root,
        customJs: rawObjectIntegrationConfiguration.custom_js,
        metadataFields: mapObjectIntegrationConfigurationMetadataFields(rawObjectIntegrationConfiguration),
        fields: mapFields(rawObjectIntegrationConfiguration.fields),
        extraIntegratedFields: mapExtraIntegratedFields(rawObjectIntegrationConfiguration),
        externalRecordUrlTemplate: rawObjectIntegrationConfiguration.external_record_url_template,
        mainRelatedObject: rawObjectIntegrationConfiguration.main_related_object
            ? mapMainRelatedObject(rawObjectIntegrationConfiguration.main_related_object)
            : null,
    };
};

const mapFromAPI = (rawIntegrationConfiguration: IntegrationConfigurationSchema): IntegrationConfiguration => {
    return {
        objectIntegrationConfigurations: mapObjectIntegrationConfigurations(
            rawIntegrationConfiguration.badger_object_definitions
        ),
    };
};

const mapCRMFieldMapToAPI = (crmFieldMap: CRMFieldMap): object => {
    return {
        crm_field_name: crmFieldMap.crmFieldName,
        crm_field_type: crmFieldMap.crmFieldType,
    };
};

const mapCRMMappingToAPI = (crmMapping: CRMFieldMap | CRMAddressMap): object => {
    if ('city' in crmMapping) {
        return {
            city: mapCRMFieldMapToAPI(crmMapping.city),
            country: mapCRMFieldMapToAPI(crmMapping.country),
            zip_code: mapCRMFieldMapToAPI(crmMapping.zipCode),
            state: mapCRMFieldMapToAPI(crmMapping.state),
            street: mapCRMFieldMapToAPI(crmMapping.street),
            latitude: crmMapping.latitude ? mapCRMFieldMapToAPI(crmMapping.latitude) : null,
            longitude: crmMapping.longitude ? mapCRMFieldMapToAPI(crmMapping.longitude) : null,
        };
    }
    return mapCRMFieldMapToAPI(crmMapping);
};

const mapBaseFieldToAPI = (field: Field): object => {
    return {
        type: field.type,
        id: parseInt(field.id),
        name: field.name,
        label: field.label,
        is_mandatory: field.isMandatory,
        is_searchable: field.isSearchable,
        is_filterable: field.isFilterable,
        is_colorizable: field.isColorizable,
        is_view_only: field.isViewOnly,
        display_order: field.displayOrder,
        sorting_order: field.sortingOrder,
        sorting_order_direction: field.sortingOrderDirection,
        display_type: field.displayType,
        crm_mapping: field.crmMapping ? mapCRMMappingToAPI(field.crmMapping) : null,
    };
};

const mapFieldToAPI = (field: Field): object => {
    return {
        ...(field.type === FieldType.TEXT && {
            is_text_area: field.isTextArea,
            max_length: field.maxLength,
        }),
        ...(field.type === FieldType.NUMBER && {
            min_value: field.minValue,
            max_value: field.maxValue,
            decimals: field.decimals,
        }),
        ...(field.type === FieldType.PICK_LIST && {
            options: field.options.map((option) => {
                return {
                    id: option.id ? parseInt(option.id) : null,
                    label: option.label,
                    value: option.value,
                    order: option.order,
                };
            }),
        }),
        ...(field.type === FieldType.ADDRESS && {
            is_main: field.isMain,
            accuracy_types: field.accuracyTypes,
        }),
        ...(field.type === FieldType.LOOK_UP && {
            related_object_types: field.relatedObjectTypes,
        }),
    };
};

const mapObjectIntegrationConfigurationsToAPI = (
    objectIntegrationConfigurations: ObjectIntegrationConfiguration[]
): ObjectIntegrationConfigurationSchema[] => {
    return objectIntegrationConfigurations.map((objectIntegrationConfiguration: ObjectIntegrationConfiguration) => {
        return {
            id: objectIntegrationConfiguration.id ? parseInt(objectIntegrationConfiguration.id) : null,
            mapping_id: objectIntegrationConfiguration.mappingId,
            object_type: objectIntegrationConfiguration.objectType,
            crm_object_type: objectIntegrationConfiguration.crmObjectType,
            label: objectIntegrationConfiguration.label,
            label_plural: objectIntegrationConfiguration.labelPlural,
            is_root: objectIntegrationConfiguration.isRoot,
            custom_js: objectIntegrationConfiguration.customJs,
            metadata_fields: {
                external_owner_id:
                    objectIntegrationConfiguration.metadataFields.externalOwnerId != null
                        ? {
                              crm_name: objectIntegrationConfiguration.metadataFields.externalOwnerId.crmName,
                              type: objectIntegrationConfiguration.metadataFields.externalOwnerId.type,
                          }
                        : null,
                external_record_id: {
                    crm_name: objectIntegrationConfiguration.metadataFields.externalRecordId.crmName,
                    type: objectIntegrationConfiguration.metadataFields.externalRecordId.type,
                },
                last_modification_date: {
                    crm_name: objectIntegrationConfiguration.metadataFields.lastModificationDate.crmName,
                    type: objectIntegrationConfiguration.metadataFields.lastModificationDate.type,
                },
                creation_date: {
                    crm_name: objectIntegrationConfiguration.metadataFields.creationDate.crmName,
                    type: objectIntegrationConfiguration.metadataFields.creationDate.type,
                },
            },
            fields: objectIntegrationConfiguration.fields.map((field: Field): FieldSchema => {
                return {
                    ...mapBaseFieldToAPI(field),
                    ...mapFieldToAPI(field),
                    type: field.type,
                } as FieldSchema;
            }),
            extra_integrated_fields: objectIntegrationConfiguration.extraIntegratedFields.map(
                (extraIntegratedField) => {
                    return {
                        field_name: extraIntegratedField.fieldName,
                        crm_name: extraIntegratedField.crmName,
                        type: extraIntegratedField.type,
                    };
                }
            ),
            external_record_url_template: objectIntegrationConfiguration.externalRecordUrlTemplate,
            main_related_object: objectIntegrationConfiguration.mainRelatedObject
                ? {
                      badger_object_definition: objectIntegrationConfiguration.mainRelatedObject.relatedObjectType,
                      lookup_field: objectIntegrationConfiguration.mainRelatedObject.lookUpField,
                  }
                : null,
        };
    });
};

const mapToAPI = (integrationConfiguration: IntegrationConfiguration): IntegrationConfigurationSchema => {
    return {
        badger_object_definitions: mapObjectIntegrationConfigurationsToAPI(
            integrationConfiguration.objectIntegrationConfigurations
        ),
    };
};

export { mapFromAPI, mapToAPI, mapMetadataFields, mapObjectIntegrationConfiguration };
