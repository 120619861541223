import { Dialog, GenericInputField } from 'design-system';
import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import useEditUserModalController from './useEditUserModalController';

export interface Props {
    visible: boolean;
    onSave: () => void;
    onClose: () => void;
    userId: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
}

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    padding: 20px 0;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    gap: 20px;
    width: 500px;
`;

const EditUserModal = ({ visible, onSave, onClose, userId, username, firstName, lastName, email }: Props) => {
    const { control, handleSubmit } = useEditUserModalController({ userId, onSave, firstName, lastName, email });

    return (
        <Dialog
            title={'Edit user'}
            subtitle={'Editable fields: Name, Surname, and Email'}
            primaryButtonCaption={'Save'}
            onPrimaryButtonClick={handleSubmit}
            primaryButtonDisabled={false}
            secondaryButtonCaption={'Cancel'}
            onSecondaryButtonClick={onClose}
            maxWidth={'sm'}
            open={visible}
            onClose={onClose}
            content={
                <Container>
                    <FormContainer>
                        <GenericInputField label="Username" value={username} updateValue={() => {}} disabled />
                        <GenericInputField label="User ID" value={userId} updateValue={() => {}} disabled />
                        <Controller
                            name="firstName"
                            control={control}
                            defaultValue={firstName}
                            render={({ field }) => (
                                <GenericInputField
                                    label="Name"
                                    value={field.value}
                                    updateValue={field.onChange}
                                    placeholder="Name"
                                />
                            )}
                        />
                        <Controller
                            name="lastName"
                            control={control}
                            defaultValue={lastName}
                            render={({ field }) => (
                                <GenericInputField
                                    label="Surname"
                                    value={field.value}
                                    updateValue={field.onChange}
                                    placeholder="Surname"
                                />
                            )}
                        />
                        <Controller
                            name="email"
                            control={control}
                            defaultValue={email}
                            render={({ field }) => (
                                <GenericInputField
                                    label="Email"
                                    value={field.value}
                                    updateValue={field.onChange}
                                    placeholder="Email"
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                                <GenericInputField
                                    label="Password"
                                    value={field.value}
                                    updateValue={field.onChange}
                                    placeholder="Password"
                                />
                            )}
                        />
                    </FormContainer>
                </Container>
            }
        />
    );
};

export default EditUserModal;
