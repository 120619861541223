import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectCompany } from '../../common/hooks/useSelectCompany';
import { useIntegrationConfigurationRepository } from '../../common/hooks/useIntegrationConfigurationRepository';
import { ObjectIntegrationConfiguration } from '../../domain/IntegrationConfiguration/IntegrationConfiguration';
import { useEffect, useState } from 'react';
import { useOnOff } from '../../common/hooks/useOnOff';
import { NewObjectDefinition } from './defs';

export type Tab = {
    label: string;
    pathIdentifier: string;
};

export const useCompanyDefinitionContentController = () => {
    const location = useLocation();
    const pathLastElement = location.pathname.split('/').slice(-1)[0];
    const navigate = useNavigate();
    const { selectedCompanyId } = useSelectCompany();
    const { integrationConfiguration, isLoading, isLoadError, isSaving } = useIntegrationConfigurationRepository();
    const [newObjectDefinitions, setNewObjectDefinitions] = useState<NewObjectDefinition[]>([]);

    const modalSpecifyLabelsControl = useOnOff();
    const modalObjectDefinitionsSelectorControl = useOnOff();

    const tabs = integrationConfiguration
        ? integrationConfiguration.objectIntegrationConfigurations.map(
              (objectIntegrationConfiguration: ObjectIntegrationConfiguration) => ({
                  label: objectIntegrationConfiguration.labelPlural,
                  pathIdentifier: objectIntegrationConfiguration.objectType,
              })
          )
        : [];

    useEffect(() => {
        if (tabs.length) {
            const index = tabs.findIndex((segment: Tab) => segment.pathIdentifier.includes(pathLastElement));
            if (index === -1) {
                navigate(`/companies/${selectedCompanyId}/field-settings/${tabs[0].pathIdentifier}`);
            }
        }
    });

    const handleBackButton = () => {
        modalSpecifyLabelsControl.setOff();
        modalObjectDefinitionsSelectorControl.setOn();
    };

    const handleNextButton = (newObjectDefinitions: NewObjectDefinition[]) => {
        modalObjectDefinitionsSelectorControl.setOff();
        modalSpecifyLabelsControl.setOn();
        setNewObjectDefinitions(newObjectDefinitions);
    };

    return {
        tabs,
        isSaving,
        isLoading,
        isLoadError,
        handleBackButton,
        handleNextButton,
        newObjectDefinitions,
        modalObjectDefinitionsSelectorControl,
        modalSpecifyLabelsControl,
    };
};
export type CompanyDefinitionContentControllerType = typeof useCompanyDefinitionContentController;
