import React from 'react';
import { Alignments, Button, ButtonTypes, Icon, IconNames } from 'design-system';
import styled from 'styled-components';
import { useUsernameCellController } from './useUsernameCellController';

const UsernameContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    &:hover .go-button {
        visibility: visible;
    }
`;

const UsernameValueContainer = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
`;

const OpenButton = styled(Button)`
    visibility: hidden;
`;

const OpenButtonContainer = styled.div`
    width: 24px;
`;

export interface Props {
    userId: string;
    username: string;
}

const UsernameCell = ({ userId, username }: Props) => {
    const { onClick } = useUsernameCellController({ userId });

    return (
        <UsernameContainer>
            <UsernameValueContainer>{username}</UsernameValueContainer>
            <OpenButtonContainer>
                <OpenButton
                    className="go-button"
                    type={ButtonTypes.TEXT}
                    alignment={Alignments.ROW}
                    icon={<Icon icon={IconNames.OPEN} width={24} />}
                    onClick={(e) => {
                        e.preventDefault();
                        onClick();
                    }}
                >
                    <></>
                </OpenButton>
            </OpenButtonContainer>
        </UsernameContainer>
    );
};

export default UsernameCell;
