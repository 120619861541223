import { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectSelectedCompanyId } from '../../../store/slices/companiesSlice';
import { useNavigate } from 'react-router-dom';
import { useCompanyUsersRepository } from '../../../common/hooks/useCompanyUsersRepository';
import { useSelectDraftUsers } from '../../../common/hooks/useSelectDraftUsers';
import { CompaniesDataSource } from '../../../CompaniesPage/api/CompaniesDataSource';
import { UserRowModel } from './UserRowModel';

const useCompanyUsersMainContentController = () => {
    const [showAddUsersModal, setShowAddUsersModal] = useState(false);
    const navigate = useNavigate();
    const { draftUsers, selectDraftUsersToUpdate } = useSelectDraftUsers();
    const { getCRMConnectionURL } = CompaniesDataSource();
    const companyId = useAppSelector(selectSelectedCompanyId);

    const [isEditUserModalVisible, setShowEditUserModal] = useState(false);
    const showEditUserModal = () => setShowEditUserModal(true);
    const hideEditUserModal = () => setShowEditUserModal(false);

    const [clickedUser, setClickedUser] = useState<UserRowModel | undefined>(undefined);
    const { users, isLoading, fetch } = useCompanyUsersRepository();
    const usersRowModel: UserRowModel[] = users.map((user) => ({
        ...user,
        onClickEditUserButton: (userId: string) => {
            const clickedUser: UserRowModel | undefined = usersRowModel.find((user) => user.id === userId);
            setClickedUser(clickedUser);
            showEditUserModal();
        },
    }));

    const onConfirm = () => {
        setShowAddUsersModal(false);
        navigate(`/companies/${companyId}/users/add-users`);
    };

    const handleOnCancel = (userIdToDelete: string) => {
        selectDraftUsersToUpdate(draftUsers.filter((user) => user.crmUserId !== userIdToDelete));
    };

    const connectToCRM = async () => {
        if (!companyId) return;
        const url = await getCRMConnectionURL(companyId);
        window.open(url, '_blank');
    };

    const onUserEdited = async () => {
        fetch();
        hideEditUserModal();
    };

    return {
        showAddUsersModal,
        setShowAddUsersModal,
        onConfirm,
        usersRowModel,
        handleOnCancel,
        connectToCRM,
        isLoadingUsers: isLoading,
        isEditUserModalVisible,
        showEditUserModal,
        hideEditUserModal,
        clickedUser,
        onUserEdited,
    };
};

export { useCompanyUsersMainContentController };

export type useCompanyUsersMainContentControllerType = typeof useCompanyUsersMainContentController;
