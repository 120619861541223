export const tasksSF = [
    {
        name: 'Subject',
        label: 'Subject',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_TITLE',
        type: 'text',
        isTextArea: false,
    },
    {
        name: 'Description',
        label: 'Description',
        isMandatory: false,
        isSearchable: true,
        isFilterable: false,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_TITLE',
        type: 'text',
        isTextArea: true,
    },
    {
        name: 'TaskSubType',
        label: 'Task/Call',
        isMandatory: true,
        isSearchable: false,
        isFilterable: true,
        isColorizable: true,
        isViewOnly: false,
        displayType: 'AS_DETAIL',
        type: 'picklist',
        options: [
            {
                label: 'No Value Selected',
                value: 'BADGER_NULL_OPTION',
                order: -1,
            },
        ],
    },
    {
        name: 'DueDate',
        label: 'Due Date',
        isMandatory: false,
        isSearchable: false,
        isFilterable: true,
        isColorizable: false,
        isViewOnly: false,
        displayType: 'AS_SUBTITLE',
        type: 'datetime',
    },
    {
        name: 'CreatedDate',
        label: 'Created Date',
        isMandatory: false,
        isSearchable: false,
        isFilterable: true,
        isColorizable: false,
        isViewOnly: true,
        displayType: 'AS_SUBTITLE',
        type: 'datetime',
    },
];
