import { SubmitHandler, useForm } from 'react-hook-form';
import { usePatchUserMutation } from '../../../store/api/users/UsersAPI';
import { PartialUpdateUserAPISchema } from '../../../store/api/users/UsersAPISchema';

export type IEditUserForm = {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
};

export interface Props {
    onSave: () => void;
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
}

const useEditUserModalController = ({ userId, onSave, firstName, lastName, email }: Props) => {
    const { handleSubmit, control } = useForm<IEditUserForm>({
        defaultValues: {
            firstName: firstName,
            lastName: lastName,
            email: email,
        },
    });

    const [patchUser] = usePatchUserMutation();

    const onSubmit: SubmitHandler<IEditUserForm> = async (data) => {
        const userData: PartialUpdateUserAPISchema = {
            first_name: data.firstName,
            last_name: data.lastName,
            email: data.email,
        };
        if (data.password) {
            userData['password'] = data.password;
        }
        await patchUser({ userId, userData }).unwrap();
        onSave();
    };

    return {
        control,
        handleSubmit: handleSubmit(onSubmit),
        onSubmit,
    };
};

export default useEditUserModalController;
