import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../commonAPIConfig';
import { mapFromAPI } from './ObjectTypeFieldsMapper';
import { ObjectTypeFields } from '../../../domain/Crm/ObjectTypeFields';
import { ObjectTypeFieldsSchema } from './ObjectTypeFieldsSchema';

export const ObjectTypeFieldsAPI = createApi({
    reducerPath: 'ObjectTypeFieldsApi',
    baseQuery,
    tagTypes: ['ObjectTypeFields'],
    endpoints: (builder) => ({
        getObjectTypeFields: builder.query<ObjectTypeFields, { companyId: string; objectType: string }>({
            query: ({ companyId, objectType }) => `/api/3/crm/companies/${companyId}/object-types/${objectType}/fields`,
            transformResponse: (response) => mapFromAPI(response as ObjectTypeFieldsSchema),
            providesTags: (result, error, { companyId, objectType }) => [
                { type: 'ObjectTypeFields', companyId, objectType },
            ],
        }),
    }),
});

export const { useGetObjectTypeFieldsQuery } = ObjectTypeFieldsAPI;
