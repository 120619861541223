import { NewObjectDefinition } from './mappers/createOrUpdateCompanyDefinition';

import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { ObjectLabelRow, SPECIFY_LABELS_MODAL_COLUMNS } from './defs';
import { Option } from '../defs';
import useFormController from './hooks/useFormController';
import { useIntegrationConfigurationTemplatesRepository } from './hooks/useIntegrationConfigurationTemplatesRepository';
import { useIntegrationConfigurationRepository } from '../../../common/hooks/useIntegrationConfigurationRepository';
import {
    DisplayType,
    FieldType,
    IntegrationConfiguration,
    ObjectIntegrationConfiguration,
    SortingOrderDirectionType,
    TextField,
} from '../../../domain/IntegrationConfiguration/IntegrationConfiguration';

export const useSpecifyLabelsModalController = (newObjectDefinitions: NewObjectDefinition[], close: () => void) => {
    const [rows, setRows] = useState<ObjectLabelRow[]>([]);
    const [isSavingChanges, setIsSavingChanges] = useState(false);

    const {
        integrationConfiguration,
        editIntegrationConfiguration,
        isSaving: isStoreSaving,
    } = useIntegrationConfigurationRepository();

    const { buildDefaultValues, handleSubmit, control, isDirty, isValid } = useFormController();

    const { integrationConfigurationTemplates } = useIntegrationConfigurationTemplatesRepository();
    const crmTemplateLabels = integrationConfigurationTemplates
        ? integrationConfigurationTemplates?.templates.map((template) => template.name)
        : [];
    if (integrationConfigurationTemplates) {
        crmTemplateLabels.push('Default');
    }

    const columns = SPECIFY_LABELS_MODAL_COLUMNS;

    useEffect(() => {
        if (isStoreSaving) {
            setIsSavingChanges(true);
        }
        if (!isStoreSaving && isSavingChanges) {
            close();
            setIsSavingChanges(false);
        }
    }, [isStoreSaving]);

    const buildTemplateOptions = (): Option[] => {
        return crmTemplateLabels.map((label) => ({ value: label, label, uuid: label }));
    };

    const buildRowsForCurrentObjectDefinition = () => {
        return integrationConfiguration?.objectIntegrationConfigurations.map(
            (objectIntegrationConfiguration: ObjectIntegrationConfiguration) => {
                return {
                    uuid: uuidv4(),
                    badgerApiName: objectIntegrationConfiguration.objectType,
                    crmLabel: objectIntegrationConfiguration.crmObjectType,
                    badgerLabel: objectIntegrationConfiguration.label,
                    badgerLabelPlural: objectIntegrationConfiguration.labelPlural,
                    templateSelector: '',
                    newObject: false,
                };
            }
        );
    };

    const createDefaultRows = (newObjectDefinitions: NewObjectDefinition[]) => {
        const objectRows: ObjectLabelRow[] = buildRowsForCurrentObjectDefinition() || [];
        const newObjectRows = newObjectDefinitions.map((newDefinition) => ({
            uuid: uuidv4(),
            badgerApiName: newDefinition.name,
            crmLabel: newDefinition.label,
            badgerLabel: '',
            badgerLabelPlural: '',
            templateSelector: 'Default',
            newObject: true,
        }));

        setRows([...objectRows, ...newObjectRows]);
        buildDefaultValues(SPECIFY_LABELS_MODAL_COLUMNS, [...objectRows, ...newObjectRows]);
    };

    useEffect(() => {
        createDefaultRows(newObjectDefinitions);
    }, [newObjectDefinitions]);

    const handleSaveChanges = () => {
        const submit = handleSubmit((data) => {
            if (rows?.length) {
                const fieldsRegister = buildFieldsRegister(data);
                const updatedIntegrationConfiguration = createUpdatedIntegrationConfiguration(fieldsRegister);
                editIntegrationConfiguration(updatedIntegrationConfiguration);
            }
        });
        submit();
    };

    const buildFieldsRegister = (data: { [p: string]: string | boolean }) => {
        const fieldsRegister: { [uuid: string]: ObjectLabelRow } = {};
        Object.entries(data).forEach(([key, value]) => {
            const [id, fieldName] = key.split('//');
            if (!fieldsRegister[id]) {
                fieldsRegister[id] = {
                    uuid: id,
                    badgerApiName: '',
                    crmLabel: '',
                    badgerLabel: '',
                    badgerLabelPlural: '',
                    templateSelector: '',
                    newObject: false,
                };
            }
            if (fieldsRegister[id]) {
                fieldsRegister[id][fieldName] = value;
            }
        });
        return fieldsRegister;
    };

    const createUpdatedIntegrationConfiguration = (data: { [p: string]: ObjectLabelRow }) => {
        const editedObjectIntegrationConfiguration = [];
        for (const rawObject in data) {
            const object = data[rawObject];
            const objectIntegrationConfiguration = integrationConfiguration?.objectIntegrationConfigurations.find(
                (objectIntegrationConfiguration) => objectIntegrationConfiguration.objectType === object.badgerApiName
            );
            if (objectIntegrationConfiguration) {
                const updatedObjectIntegrationConfiguration = {
                    ...objectIntegrationConfiguration,
                    label: object.badgerLabel,
                    labelPlural: object.badgerLabelPlural,
                };
                editedObjectIntegrationConfiguration.push(updatedObjectIntegrationConfiguration);
            } else {
                const newObjectIntegrationConfiguration = buildNewObjectIntegrationConfiguration(object);
                editedObjectIntegrationConfiguration.push(newObjectIntegrationConfiguration);
            }
        }
        return {
            objectIntegrationConfigurations: editedObjectIntegrationConfiguration,
        } as IntegrationConfiguration;
    };

    const buildNewObjectIntegrationConfiguration = (object: ObjectLabelRow) => {
        let newObjectIntegrationConfiguration: ObjectIntegrationConfiguration | null;

        const template = integrationConfigurationTemplates?.templates.find(
            (template) => template.name === object.templateSelector
        );
        if (template) {
            newObjectIntegrationConfiguration = template.objectIntegrationConfiguration;
            newObjectIntegrationConfiguration.label = object.badgerLabel;
            newObjectIntegrationConfiguration.labelPlural = object.badgerLabelPlural;
            newObjectIntegrationConfiguration.objectType = object.badgerApiName;
            newObjectIntegrationConfiguration.crmObjectType = object.crmLabel;
        } else {
            if (!integrationConfigurationTemplates) {
                throw new Error('Integration configuration templates not found');
            }
            newObjectIntegrationConfiguration = {
                id: null,
                objectType: object.badgerApiName,
                crmObjectType: object.badgerApiName,
                label: object.badgerLabel,
                labelPlural: object.badgerLabelPlural,
                isRoot: true,
                customJs: integrationConfigurationTemplates.genericTemplates.customJs,
                metadataFields: integrationConfigurationTemplates.genericTemplates.metadataFields,
                mappingId: null,
                extraIntegratedFields: [],
                externalRecordUrlTemplate: null,
                mainRelatedObject: null,
                fields: [
                    {
                        type: FieldType.TEXT,
                        isTextArea: false,
                        maxLength: 50,
                        name: 'example_field',
                        label: 'Example Field - change me',
                        isMandatory: false,
                        isSearchable: false,
                        isFilterable: false,
                        isColorizable: false,
                        isViewOnly: false,
                        displayOrder: 0,
                        sortingOrder: 0,
                        sortingOrderDirection: SortingOrderDirectionType.ASCENDING,
                        displayType: DisplayType.AS_DETAILS,
                    } as TextField,
                ],
            };
        }
        return newObjectIntegrationConfiguration;
    };

    return {
        rows,
        createDefaultRows,
        control,
        isDirty,
        isValid,
        isSavingChanges,
        handleSaveChanges,
        columns,
        buildTemplateOptions,
    };
};
