import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQuery } from '../commonAPIConfig';
import { IntegrationConfigurationTemplates } from '../../../domain/IntegrationConfigurationTemplate/IntegrationConfigurationTemplate';
import { mapFromAPI } from './IntegrationConfigurationTemplateMapper';
import { IntegrationConfigurationTemplatesSchema } from './IntegrationConfigurationTemplateSchema';
export const IntegrationConfigurationTemplateAPI = createApi({
    reducerPath: 'IntegrationConfigurationTemplateApi',
    baseQuery,
    tagTypes: ['IntegrationConfigurationTemplate'],
    endpoints: (builder) => ({
        getIntegrationConfigurationTemplate: builder.query<IntegrationConfigurationTemplates, string>({
            query: (companyId) => `/api/3/crm/companies/${companyId}/integration-configuration-templates`,
            transformResponse: (response) => mapFromAPI(response as IntegrationConfigurationTemplatesSchema),
            providesTags: (result, error, companyId) => [{ type: 'IntegrationConfigurationTemplate', companyId }],
        }),
    }),
});

export const { useGetIntegrationConfigurationTemplateQuery } = IntegrationConfigurationTemplateAPI;
