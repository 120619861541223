import { User, UserState } from '../../../domain/Users/User';
import { UserAPISchema } from './UsersAPISchema';

export const mapUserFromAPI = (response: UserAPISchema): User => ({
    id: response.id,
    username: response.username,
    firstName: response.first_name,
    lastName: response.last_name,
    email: response.email,
    dateJoined: _mapDate(response.date_joined),
    state: _mapState(response.state),
});

const _mapDate = (date: string): string => {
    return new Date(Date.parse(date)).toDateString();
};

const _mapState = (state: string): UserState => {
    switch (state) {
        case 'ACTIVE':
            return UserState.ACTIVE;
        case 'INACTIVE':
            return UserState.INACTIVE;
        default:
            throw new Error('Invalid user state');
    }
};
